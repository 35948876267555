@import 'import';
.item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  :global(.form-check) {
    // margin-top: -16px;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &:focus {
      outline: 2px solid $black;
      // outline-offset: -2px;
    }
  }

  .itemContent {
    cursor: pointer;
    display: flex;
    flex-grow: 1;

    .image {
      position: relative;
      flex-shrink: 0;

      img {
        border-radius: 2px;
      }

      .imageLabel {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        visibility: hidden;
        opacity: 0;

        transition: opacity 0.3s ease;

        &Text {
          @include font($size: rem(10), $line-height: 14px, $weight: 500, $color: $grey-text);

          height: 14px;
          padding: 0 7px;

          letter-spacing: 0;

          background-color: $lightwhite;
          border-radius: 10px;
        }

        &Checked {
          visibility: visible;
          opacity: 1;
        }
      }

      &,
      img,
      .imageLabel {
        width: 90px;
        height: 67px;
      }
    }

    .description {
      flex-direction: column;
      flex-grow: 1;

      margin-left: 10px;
      padding: 5px 0 0;

      border-bottom: 1px solid $gray-010;

      @include media-breakpoint-down(sm) {
        padding: 13px 0;
      }

      &Name {
        @include font($size: rem(15), $line-height: rem(18), $weight: 500, $color: $black);

        letter-spacing: 0;
        word-wrap: break-word;
      }

      &Title {
        @include font($size: rem(12), $line-height: rem(16), $weight: 400, $color: $grey-text);

        letter-spacing: 0;
      }

      &Selected {
        border-bottom-color: $vv-red;
      }
    }

    .arrow {
      position: relative;
      top: -10px;

      align-self: center;

      height: 24px;
      margin: 0 3px;
      padding: 0;

      color: $vv-red;

      background: transparent;
      background-color: transparent;
      border: 0;

      &Desktop {
        display: block;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &Mobile {
        display: none;
        width: 24px;
        height: 24px;

        @include media-breakpoint-down(sm) {
          top: unset;
          transform: rotate(180deg);
          display: block;

          :global(.Icon),
          :global(.Icon span),
          svg {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

.listBox {
  :global(.ListBoxItem._focused) {
    outline: 2px solid $black;
    outline-offset: -2px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 2px;
  }
}
