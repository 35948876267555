@mixin scrollbar(
  $size: 4px,
  $outline: 2px,
  $color-bar: rgba($black, 0.04),
  $color-bar-hover: rgba($black, 0.25),
  $color-outline: $white
) {
  &::-webkit-scrollbar {
    width: calc(#{$size} + 2 * #{$outline});
    height: calc(#{$size} + 2 * #{$outline});
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-bar;
    border: $outline solid $color-outline;
    border-radius: calc(#{$size} / 2 + #{$outline});

    &:hover {
      background-color: $color-bar-hover;
    }
  }

  @content;
}

@mixin no-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}
